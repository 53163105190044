import styles from "../styles/pages/app.module.scss";
import "../styles/globals.scss";

import dynamic from "next/dynamic";

import type { AppProps } from "next/app";
import React from "react";
const Navbar = dynamic(() => import("../layout/navbar"));

import "@fortawesome/fontawesome-svg-core/styles.css"; // import Font Awesome CSS
import { config } from "@fortawesome/fontawesome-svg-core";
config.autoAddCss = false; // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

function MyApp({ Component, pageProps, router }: AppProps) {
  return (
    <div className={styles["app-container"]}>
      <Navbar />
      <Component {...pageProps} />
    </div>
  );
}
export default MyApp;
